import React from 'react';
// import { Link } from 'react-router-dom';
// import Modal from '../components/Modal';
import ListPDF from '../components/ListPDF';
import Info from '../components/Info';
// import useAuth from '../hooks/useAuth';

function HomeView() {
  // const { currentUser, handleUserLogout } = useAuth();
  return (
    <section>
      <Info text="Descarga la versión PDF" link="/Cancionero_de_oracion.pdf" />
      {/* <Info text="Descarga la versión EPUB" link="/Cancionero_de_oracion.epub" /> */}
      {/* <Modal /> */}
      <ListPDF />
    </section>
  );
}

export default HomeView;
