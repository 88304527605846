import React from 'react';

function Info({ text, link }) {
  return (
    <div className="w-full max-w-sm mx-auto mt-4">
      <div
        className="px-4 py-3 mb-2 text-cyan-900 bg-cyan-100 border-t-4 border-cyan-500 rounded-b shadow-md"
        role="alert"
      >
        <div className="flex">
          <div className="py-1">
            <svg
              className="w-6 h-6 mr-4 text-cyan-500 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div>
            <h2 className="font-bold">{text}</h2>
            <a
              href={link}
              className="text-sm text-blue-600 underline hover:text-blue-800"
            >
              Haciendo click aquí
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Info;
